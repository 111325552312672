<template>
  <authenticator
    :hide-sign-up="true"
    :form-fields="formFields"
  >
    <template v-slot:reset-password-header>
      <span></span>
    </template>

    <template v-slot:confirm-reset-password-header>
      <span></span>
    </template>
<!--
    <template v-slot:footer>
      <div style="padding: var(--amplify-space-large); text-align: center">
        <router-link
          class="text-decoration-none text-muted"
          :to="{path: '/'}"
        >
          Ritorna alla Home
        </router-link>
      </div>
    </template> -->

    <!-- <template v-slot="{ user, signOut,  }">
      <h1>Hello {{ user.username }}!</h1>
      <button @click="signOut">Sign Out</button>
    </template> -->
  </authenticator>
</template>

<script setup>
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';
import { Authenticator } from "@aws-amplify/ui-vue";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-vue/styles.css";
import awsconfig from './../aws-exports';

Amplify.configure(awsconfig);

I18n.putVocabularies(translations);

I18n.setLanguage('it');

// -------------------------------

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: 'Email',
      label: ''
    },
    password: {
      labelHidden: true
    }
  },
  resetPassword: {
    username: {
      placeholder: 'Inserisci la tua email per resettare la password',
      label: '',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: 'Inserisci il codice ricevuto via email'
    }
  }
};

// -------------------------------


</script>

<style>
[data-amplify-authenticator] [data-amplify-form] {
  padding: 0rem;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: var(--amplify-components-authenticator-router-background-color);
  box-shadow: none;
  border: none;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
}

.amplify-button--primary {
  background: var(--bs-primary);
  border-radius: 0px;
}

.amplify-button--link {
  color: var(--bs-primary)
}

.amplify-input,
.amplify-button--primary,
.amplify-button--link {
  font-size: 12px;
}

</style>