<template>
  <div class="login-wrap">
    <p
      class="pointer text-primary login-action-text text-center"
    >
      <router-link
        :to="{name: 'Home'}"
        class="text-decoration-none text-muted"
      >Torna alla Home</router-link>
    </p>

    <div class="login--item">
      <p class="text-muted heading-text mb-4 text-center">
        {{ headingText }}
      </p>

      <component :is='components[loginComponent]'></component>
    </div>

     <p
      class="mt-4 pointer text-primary login-action-text text-center d-none"
      @click="switchComponent()"
    >
      {{ loginActionText }}
    </p>
  </div>
</template>

<script setup>
import LoginForm from '../components/LoginForm.vue';
import ProviderLoginForm from '../components/ProviderLoginForm.vue';
import { ref, onMounted } from 'vue';


const components = {
  'external': LoginForm,
  'provider': ProviderLoginForm
}

const loginComponent  = ref('');
const loginActionText = ref('');
const headingText     = ref('');

function switchComponent() {
  switch (loginComponent.value) {
    case 'provider':
      loginComponent.value = 'external'
      loginActionText.value = 'Clicca qui per accedere con le credenziali aziendali'
      headingText.value = 'Inserisci email e password per accedere!'
      break;

    default:
      loginComponent.value = 'provider'
      loginActionText.value = 'Clicca qui per accedere come utente esterno'
      headingText.value = 'Esegui il login utilizzado le credenziali aziendali!'
      break;
  }
}

onMounted(() => {
  switchComponent();
})
</script>

<style scoped>
.login-wrap {
  height: 100vh;
  padding-top: 7rem;
}

.login--item {
  padding: 2rem;
  max-width: 450px;
  min-height: 150px;
  box-shadow: 0px 0px 4px 0px #aaa;
  margin: 0 auto;
}

.login-action-text {
  font-size: 12px;
}

.heading-text {
  font-size: 14px;
  font-weight: 300;
}
</style>
