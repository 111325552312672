<template>
  <div class="provired-login-form">
    <div class="access--items">
      <div class="access--item"
        v-for="(item, index) in providers"
        :key="index"
      >
        <button
          class="btn btn--access-item rounded-0 w-100 d-flex justify-content-center"
          :class="item.class"
          @click="Auth.federatedSignIn({provider: item.name})"
        >

          <span class="me-2 d-inline-block">{{ item.label }}</span>

          <img
            v-if="item.logo"
            class="access--item_logo"
            :src="getImgUrl(item.logo)"
            style="width: 15px; height: auto;"
          >
        </button>
      </div>
    </div>
  </div>

</template>

<script setup>
import { Auth, Amplify } from "aws-amplify";
import awsconfig from './../aws-exports';

Amplify.configure(awsconfig);

const providers = [{
  name: process.env.VUE_APP_PROVIDERS_NAME,
  label: 'Accedi con Microsoft',
  logo: 'microsoft.png',
  class: 'btn--samag-holding'
}];

function getImgUrl(pic) {
  return require('./../assets/images/providers/'+pic)
}
</script>

<style scoped>
.access--item {
  margin-bottom: 1rem;
}

.access--item:last-child {
  margin-bottom: 0rem;
}

.btn--access-item {
  border: 1px solid;
  font-size: 12px;
  align-items: center;
  transition: all .2s linear;
}

.access--item_logo {
  transition: all .2s linear;
}

.btn--samag-holding {
  color: #5b6771;
  border-color: #5b6771;
}

.btn--samag-holding:hover {
  background: #57c9e8;
  border-color: #57c9e8;
}

.btn--idia {
  color: #b32572;
  border-color:#b32572;
}

.btn--idia:hover {
  background: #b32572;
}

.btn--samag_tech {
  color: #e77204;
  border-color:#e77204;
}

.btn--samag_tech:hover {
  background: #e77204;
}

.btn--cbs_lavoro {
  color: #1f58a4;
  border-color:#1f58a4;
}

.btn--cbs_lavoro:hover {
  background: #1f58a4;
}

.btn--access-item:hover {
  color: white
}
/* .btn--access-item:hover .access--item_logo{
  filter: brightness(0) invert(1)
} */
</style>